import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const MEDICAL_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "Allergy shots ",
            sectionCopy1: "Medications like epinephrine injections are common treatments for severe allergic reactions. When a patient has a life-threatening response to an allergen, epinephrine may be used alongside other emergency medical care to prevent the effects from worsening. These injections may be administered by a health care worker, but patients who have severe allergic reactions should  keep epinephrine injections close to them in case of unexpected rapid development of an allergic reaction.",
            sectionId1: "allergy-shots",
            sectionHeader2: "Medications (Inhalers, nose sprays, oral medications, emergency medications) ",
            sectionCopy2: "Medications to treat lung and allergy conditions are common. These can include over-the-counter medications and prescription medications. Antihistamines and steroids are common types of medications used to treat these conditions and come in oral medications, nasal sprays, inhalers and emergency medications like epinephrine injections. Side effects tend to be minor, but drowsiness and irritation of the mouth and throat may be present.",
            sectionId2: "medications",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: "",
            sectionHeader1: "Biologic agents (injected medications) ",
            sectionCopy1: "Biologic medications are medications that come from living organisms. They contain proteins and chemicals to treat specific conditions. These medications are typically injected and are used to help manage severe asthma and prevent recurrences of flare-ups. Patients who have eczema may find biologic agents to be a sufficient treatment to help manage their symptoms.",
            sectionId1: "biologic-agents",
            sectionHeader2: "",
            sectionCopy2: "",
            sectionId2: "",
            cssClass: "",
            id: 2
        },

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default MEDICAL_ONCOLOGY_DATA; 
