import navLogo from "../../img/standard/logo.svg";

const NAVIGATION_DATA = {
    navLogo: navLogo,
    homeUrl: '/',
    linkItems: [
        {
            id: 1,
            name: 'Lung',
            link: '/lung',
            children: [
                {
                    name: 'Conditions',
                    link: '/lung/conditions'
                },
                {
                    name: 'Tests',
                    link: '/lung/tests'
                },
                {
                    name: 'Treatments',
                    link: '/lung/treatments'
                },

            ]
        },
        {
            id: 2,
            name: 'Allergy',
            link: '/allergy',
            children: [
                {
                    name: 'Conditions',
                    link: '/allergy/conditions'
                },
                {
                    name: 'Tests',
                    link: '/allergy/tests'
                },
                {
                    name: 'Treatments',
                    link: '/allergy/treatments'
                },
            ],
        },
       {
           id: 3,
           name: 'Providers',
           link: '/providers',
           children: [
          ]
      },
        {
            id: 4,
            name: 'Locations',
            link: '/locations',
            children: []
        },
        {
            id: 7,
            name: 'Patient Resources',
            link: '/patient-resources',
            children: []
        },
    ],
    rightHtml: ``
}

export default NAVIGATION_DATA;
