import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const MEDICAL_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "Pulmonary function testing",
            sectionCopy1: "Pulmonary function testing, also called spirometry measures how well a person breathes air in and out of their lungs. During the test, a patient will be asked to breathe for different lengths of time and at different speeds. This test will give details about lung volume, and how fast and easy it is for a person to exhale. The test will be done for roughly an hour with our certified pulmonary function technician. Our pulmonary function technologist is nationally accredited with 25 years of experience.",
            sectionId1: "pulmonary-function-testing",
            sectionHeader2: "Arterial blood gas measurement ",
            sectionCopy2: "Arterial blood gas (ABG) test measures the amount of carbon dioxide and oxygen in the blood. Low oxygen in the blood called hypoxia, can be due to many lung conditions. The ABG test can tell a provider more about potential conditions that cause lung function to decline. ABG tests also measure the pH balance to determine if blood is more acidic or alkaline.",
            sectionId2: "arterial-blood-gas-measurement",
            cssClass: "border-bottom",
            id: 7
        },
        {
            imageUrl: "",
            sectionHeader1: "Chest imaging",
            sectionCopy1: "There are different types of tests that can be done to help diagnose conditions of the lung. Computed tomography (CT) scans provide a 3-D view of the lung structure and vessels. This test is performed with the patient laying down while the machine scans the chest and produces images. The CT scan can be done with our without dye contrast. The results of the test can help the doctor see things like clots, nodules, infections or any other abnormalities. An X-ray is another helpful imaging tool that shows the size of the lungs and infections like pneumonia. This test is done quickly with no special preparation. ",
            sectionId1: "chest-imaging",
            sectionHeader2: "",
            sectionCopy2: "",
            sectionId2: "",
            cssClass: "",
            id: 8
        },

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default MEDICAL_ONCOLOGY_DATA; 
