import './App.css';
import './main'
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.styles.scss'
import React from 'react';
import {Switch, Route} from 'react-router-dom';



import AuxiliaryComponent from "./components/auxiliary/auxiliary-bar.component"
import NavigationComponent from "./components/navigation/navigation.component";
import HomePage from './pages/homepage/homepage.component';
import ProvidersPage from "./pages/providers-gallery/providers-gallery.component";
import LocationsPage from "./pages/locations/locations.component";
import FooterComponent from "./components/footer/footer.component"
import AboutUsPage from "./pages/about-us/about-us.component";
import ProviderTemplatePage from "./pages/providers-single-page/providers-template.component";
import ArticlePage from "./articles/articles.component";

import LungTestsPage from './pages/lung/tests/lung-tests.component'
import LungConditionsPage from './pages/lung/conditions/lung-conditions.component'
import LungTreatmentsPage from "./pages/lung/treatment/lung-treatments.component";

import AllergyTestsPage from "./pages/allergy/tests/allergy-tests.component";
import AllergyConditionsPage from './pages/allergy/conditions/allergy-conditions.component'
import AllergyTreatmentsPage from "./pages/allergy/treatment/allergy-treatments.component";
import PatientResourcesGeneralPage from "./pages/patient-resources/patient-resources-general.component";




class App extends React.Component {
    render(){
        return (
            <main>
                <AuxiliaryComponent/>
                <NavigationComponent/>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route exact
                               path='/about-us'
                               component={AboutUsPage}
                        />
                        <Route exact path='/providers'
                               component={ProvidersPage}
                        />
                        <Route exact
                               path='/locations'
                               component={LocationsPage}
                        />
                        <Route exact
                               path='/lung/conditions'
                               component={LungConditionsPage}
                        />
                        <Route exact
                               path='/lung/tests'
                               component={LungTestsPage}
                        />
                        <Route exact
                               path='/lung/treatments'
                               component={LungTreatmentsPage}
                        />
                        <Route exact
                               path='/allergy/conditions'
                               component={AllergyConditionsPage}
                        />
                        <Route exact
                               path='/allergy/tests'
                               component={AllergyTestsPage}
                        />
                        <Route exact
                               path='/allergy/treatments'
                               component={AllergyTreatmentsPage}
                        />
                        <Route path='/providers/:providerId' component={ProviderTemplatePage} />
                        <Route path='/articles/:articleId' component={ArticlePage} />
                        <Route exact path='/patient-resources' component={PatientResourcesGeneralPage} />

                        <Route component={HomePage} />
                    </Switch>
                <FooterComponent/>
            </main>
        );
    }
}

export default App;
