const LOCATIONS_DATA = {
    locations: [
        {
            title: 'Optum Allergy',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO9JyFYi4yIARbZ65rPu9T38&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.076087,-115.297047&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=6190+S+Fort+Apache+Rd+Las+Vegas,+NV+89148',
            address1: '6190 S. Fort Apache Rd.',
            address2: 'Las Vegas, NV 89148',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 1
        },
        {
            title: 'Optum Allergy',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:EkhQYXNlbyBWZXJkZSBQbGF6YSwgNzUgUyBWYWxsZSBWZXJkZSBEciBzdGUgMTAwLCBIZW5kZXJzb24sIE5WIDg5MDEyLCBVU0EiIxohChYKFAoSCQm2LBSW0ciAEZZDyM-4wdFeEgdzdGUgMTAw&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://maps.app.goo.gl/MFmtXJZza9zZvuUEA',
            address1: '75 S. Valle Verde Dr., Ste. 100',
            address2: 'Henderson, NV 89012',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 2
        },
        {
            title: 'Optum Pulmonology',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbwQdJenDyIAR4hiNqrAfAlI&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://maps.app.goo.gl/wsBXhvnM3rB4eFF1A',
            address1: '2300 W. Charleston Blvd.',
            address2: 'Las Vegas, NV 89102',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 3
        },
    ]
}

export default LOCATIONS_DATA;


