import React, {Component, useEffect, useState} from 'react';
import HeaderComponent from "../../components/header/header.component";
import HomepageGalleryComponent from "../../components/homepage-gallery/homepage-gallery.component";
import headerImage from "../../img/OCLA/homepage/hero-banner.png";
import ctaImage from "../../img/OCLA/CTA/CTA_OCLA.png";
import HOMEPAGE_DATA from "./homepage.data";
import CTAComponent from "../../components/call-to-action/cta-footer.component";
import Interior5050Component from "../../components/interior-50-50/interior-50-50.component";
import {hideMask} from "../../components/navigation/navigation.component";
import './homepage.styles.scss'
import CardListComponent from "../../components/Card_List_Optum/card-list";

import firebase from '../../firebase.config';

function HomePage() {
    const DATA = HOMEPAGE_DATA;

    const [clinicalTrials, setClinicalTrials] = useState([]);

    useEffect( () => {
    const fetchClinicalTrials = async () => {
        const db = firebase.firestore()
        const data = await db.collection("clinicalTrials").get()
        setClinicalTrials(data.docs.map( doc => doc.data()))
    }
    fetchClinicalTrials()
}, [])


        return(
            <>
                <main className='page-body' onClick={hideMask}>
                        <HeaderComponent imageUrl={headerImage}
                                         title="Nevada's leading lung and allergy specialists"
                                         innerCopy="Integrated care from a team of compassionate providers"
                                         topText="Optum Care Lung and Allergy"
                                         isTitleDash={true}
                                         shouldHideHomeButton={true}
                        />
                        <div className='row justify-content-around'>
                            <div className="col-md-8 col-sm-12">
                                <h2 className="text-centered">Helping you stay healthy and feeling your best</h2>
                                <p className="text-centered">
                                    Many patients come to us believing that they have to live in discomfort or adjust their lifestyle because of their condition. But in reality, many conditions can be managed effectively. We can help bring patients relief so they have more energy and continue to live a healthy and active life.
                                </p>
                                <p className="text-centered">
                                    Optum Care Lung and Allergy Care is committed to providing you with the highest quality of care and treatment available. From the moment you walk through our doors, you’ll be cared for by a compassionate team of providers. Our pulmonologists treat respiratory issues and our allergists treat conditions that affect the immune system. Each of our specialists are leading doctors in their field. They provide highly specialized care for all types of conditions.
                                </p>
                                <br></br><br></br>
                            </div>
                        </div>
                        <div className="container-large">
                            <HomepageGalleryComponent/>
                        </div>

                        {
                            DATA.interiorSections.map( ({ id, ...otherSectionProps }) => (
                                <Interior5050Component key={id} {...otherSectionProps} />
                            ))
                        }
                       <div className="cta-container">
                           <CTAComponent
                               imageUrl={ctaImage}
                               htmlCopy={DATA.ctaInfo.htmlCopy}
                           />
                       </div>
                </main>
            </>
        );
}

export default HomePage;
