import placeHolderMaleGrid from '../../img/OCLA/providers/grid_updated/male_placeholder_300x300.jpg';
import placeHolderFemaleGrid from '../../img/OCLA/providers/grid_updated/female_placeholder_300x300.jpg';

import femalePlaceholder from '../../img/figma/Female-Placeholder.png';
import malePlaceholder from '../../img/figma/Male-Placeholder.png';

import ricardo_vinuya_grid from '../../img/OCLA/providers/grid_updated/19162_Vinuya_Richard_MD_300x300.png';
import ricardo_vinuya from '../../img/OCLA/providers/interior_updated/19162_Vinuya_Richard_MD_300x400.png';

import robert_rowe_grid from '../../img/OCLA/providers/grid_updated/19162_Rowe_Robert_APRN_300x300.png';
import robert_rowe from '../../img/OCLA/providers/interior_updated/19162_Rowe_Robert_APRN_300x400.png';

import rob_lampart_grid from '../../img/OCLA/providers/grid_updated/19162_Lampert_Robert_MD_300x300.png';
import rob_lampart from '../../img/OCLA/providers/interior_updated/19162_Lampert_Robert_MD_300x400.png';

import dan_hering_grid from '../../img/OCLA/providers/grid_updated/19162_Hering_Daniel_APRN_300x300.png';
import dan_hering from '../../img/OCLA/providers/interior_updated/19162_Hering_Daniel_APRN_300x400.png';

/*import patty_roberts_grid from '../../img/OCLA/providers/grid_updated/19162_Roberts_Patricia_APRN_300x300.png';
import patty_roberts from '../../img/OCLA/providers/interior_updated/19162_Roberts_Patricia_APRN_300x400.png';*/

import lisa_reiter_grid from '../../img/OCLA/providers/grid_updated/19162_Reiter_Lisa_APRN_300x300.png';
import lisa_reiter from '../../img/OCLA/providers/interior_updated/19162_Reiter_Lisa_APRN_300x400.png';

import Simhean_Lee_grid from '../../img/OCLA/providers/grid/Simhean-Lee-grid.jpg';
import Simhean_Lee from '../../img/OCLA/providers/interior/Simhean-Lee.jpg';

import Mona_Liu_grid from '../../img/OCLA/providers/grid/Mona-Liu-grid.jpg';
import Mona_Liu from '../../img/OCLA/providers/interior/Mona-Liu.jpg';





const PROVIDERS_DATA = {
    sections: [
        {
            imageUrl: ricardo_vinuya_grid,
            providerName: "Ricardo Vinuya",
            providerTitle: "MD",
            linkUrl: "ricardo-vinuya",
            providerInteriorImage: ricardo_vinuya,
            specialty: ["Allergy"],
            sex: "Male",
            providerBio: `Dr. Rick Vinuya is Chief of Optum Care Allergy Care. He's a top allergy and immunology specialist. Dr. Vinuya has been treating patients in Nevada with Optum for the past three years. He's been in practice for 28 years. He spent his first five years in academic medicine. Then, he was in private practice for 20 years. Dr. Vinuya has published many medical articles. He has done several talks on allergy issues. He has also helped lead trials for medicines. Dr. Vinuya is dedicated to his patients. He offers the care to help them live better.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of the Philippines in Metro Manila, Philippines'
                },
                {
                    type: 'Residency',
                    name: 'Philippine General Hospital in Manila Philippines | Wayne State University/Children’s Hospital of Michigan in Detroit, MI'
                },
                {
                    type: 'Fellowship',
                    name: 'Duke University in Durham, NC | Wayne State University/Children’s Hospital of Michigan in Detroit, MI '
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [1],
            id: 1
        },

        {
            imageUrl: rob_lampart_grid,
            providerName: "Robert Lampert",
            providerTitle: "MD",
            linkUrl: "robert-lampert",
            providerInteriorImage: rob_lampart,
            specialty: ["Lung"],
            sex: "Male",
            providerBio: `Robert Lampert, MD, is board-certified in internal medicine. He has been working in pulmonology for over 20 years. Dr. Lampert has completed extensive training in both pulmonology and critical care. He has published several articles on immune and lung topics. Dr. Lampert believes in spending time with his patients in order to really understand their lifestyle and concerns. He works closely with each patient to guide them toward treatment options that help them feel better. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of the Witwatersrand, Johannesburg, South Africa'
                },

                {
                    type: 'Residency',
                    name: "Michael Reese Hospital & Medical Center, Chicago, Illinois | Mount Sinai Hospital, Chicago, Illinois"
                },
                {
                    type: 'Fellowship',
                    name: 'Baylor College of Medicine, Houston, Texas'
                },

            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [2],
            id: 3
        },

        {
            imageUrl: dan_hering_grid,
            providerName: "Daniel Hering",
            providerTitle: "APRN",
            linkUrl: "daniel-hering",
            providerInteriorImage: dan_hering,
            specialty: ["Lung"],
            sex: "Male",
            providerBio: `Daniel is a board-certified nurse practitioner who has been practicing in pulmonology for the past 10 years. 
            He’s a well-rounded health care provider with experience working in both clinics and hospitals.
            Daniel is a compassionate provider who’s dedicated to the medical profession. He focuses on both the prevention and treatment of pulmonary diseases. He loves caring for patients in his community.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Nevada in Las Vegas, Nevada'
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [2, 3],
            id: 4
        },

        {
            imageUrl: lisa_reiter_grid,
            providerName: "Lisa Reiter",
            providerTitle: "APRN",
            linkUrl: "lisa-reiter",
            providerInteriorImage: lisa_reiter,
            specialty: ["Lung"],
            sex: "Female",
            providerBio: `Lisa is a board-certified nurse practitioner who has been treating patients in Las Vegas since 1991. She’s worked in the clinical setting for the past 9 years and has over 30 years of hospital critical care experience. She's had experience in many specialties including gynecology, dermatology and pulmonology and cardiothoracic surgery. Lisa is well-versed in the diseases and conditions of the lungs. She enjoys helping patients find relief and live  healthier lives. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Nevada in Las Vegas, Nevada'
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [2,4],
            id: 5
        },


        /*{
            imageUrl: patty_roberts_grid,
            providerName: "Patty Roberts",
            providerTitle: "APRN",
            linkUrl: "patty-roberts",
            providerInteriorImage: patty_roberts,
            specialty: ["Lung"],
            sex: "Female",
            providerBio: `Patty is a board-certified nurse practitioner who has been caring for patients for almost 40 years. She’s worked in pediatrics, women’s health, immunology and pulmonology. Patty has extensive experience in lecturing and performing clinical research on the topics of HIV/AIDS, asthma and allergies. She’s committed to helping her patients understand their lung conditions and finding the right methods to manage them.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Nevada in Las Vegas, Nevada'
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [3, 4],
            id: 6
        },*/
        {
            imageUrl: Mona_Liu_grid,
            providerName: "Mona Liu",
            providerTitle: "MD",
            linkUrl: "mona-liu",
            providerInteriorImage: Mona_Liu,
            specialty: ["Allergy"],
            sex: "Female",
            providerBio: `Dr. Liu is a board-certified specialist in allergy and immunology. She's a talented doctor who treats patients in both specialties. She helps with seasonal allergies, hives, asthma and other immune conditions. Dr. Liu cares about getting her patients relief so they can feel well. She loves getting to work with patients to find the right care plan for their unique needs.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Pittsburgh School of Medicine, Pittsburgh, PA'
                },
                {
                    type: 'Residency',
                    name: "Children's Hospital of Los Angeles, Los Angeles, CA"
                },
                {
                    type: 'Fellowship',
                    name: "University of California, Los Angeles, Los Angeles, CA"
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [3, 4],
            id: 7
        },   {
            imageUrl: Simhean_Lee_grid,
            providerName: "Simhean Lee",
            providerTitle: "MD",
            linkUrl: "simhean-lee",
            providerInteriorImage:Simhean_Lee,
            specialty: ["Allergy"],
            sex: "Male",
            providerBio: `Dr. Lee has over 20 years of experience as an allergist. He practiced in Korea for several years before coming to the United States. Dr. Lee enjoys getting to know his patients. He works to understand their concerns. Then, he helps them find the best treatment plan for them. Dr. Lee finds joy in helping his patients find relief from their condition.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Far Eastern University-NRMF Medical School, Manila, Philippines'
                },
                {
                    type: 'Residency',
                    name: "The State University of New York, Buffalo, NY"
                },
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [3],
            id: 8
        },

        //PROVIDER TEMPLATE
        // {
        //     imageUrl: placeHolderMaleGrid,
        //     providerName: "Text",
        //     providerTitle: "MD",
        //     linkUrl: "test-1",
        //     providerInteriorImage: malePlaceholder,
        //     specialty: ["Lung"],
        //     sex: "Male",
        //     providerBio: `bio`,
        //     providerPhilosophy: [],
        //     languages: ["English"],
        //     qualifications: [
        //         {
        //             type: 'Medical School',
        //             name: ''
        //         },
        //         {
        //             type: 'Internship',
        //             name: ''
        //         },
        //         {
        //             type: 'Residency',
        //             name: ''
        //         },
        //         {
        //             type: 'Fellowship',
        //             name: ""
        //         },
        //         {
        //             type: 'Board certifications',
        //             name: ''
        //         }
        //     ],
        //     organizations: [],
        //     professionalAssociations: [],
        //     publicationsAndResearch: [],
        //     providerLocations: [8],
        //     id: 6
        // },





    ],
    buttons: []
}

export default PROVIDERS_DATA;

