import cancerswetreat6 from '../../../img/Cancer-Care-Images/cancers-we-treat-closeface.png';
import allergyconditions1 from '../../../img/OCLA/allergy-conditions/allergy-conditions-meds.png';
import allergyconditions2 from '../../../img/OCLA/allergy-conditions/edited/allergy-conditions-pediatrics.png';
import allergyconditions3 from '../../../img/OCLA/allergy-conditions/allergy-conditions-2.png';





const CANCERS_WE_TREAT_DATA = {
    sections: [
        {
            imageUrl: allergyconditions1,
            sectionHeader1: "Nose and eye allergies",
            sectionCopy1: "Nose and eye allergies include common seasonal allergies (hay fever) and year-round (perennial) allergies. Allergies happen when the immune system responds to substances that otherwise pose no threat to humans, like pollen or certain molds. Risk factors for nose and eye allergies include having other allergies or asthma, having a relative with allergies or asthma or being exposed to allergens in the home or place of work. Symptoms include sinus congestion, postnasal drip, itchy and irritated eyes, and having a cough. Allergies can affect a patient by preventing them from getting good sleep and may lead to them developing other conditions such as ear and sinus infections or asthma. Nose and eye allergies can be treated by avoiding irritating substances, applying cold compresses, using eye drops, using over-the-counter antihistamines and with prescription steroids and antihistamines.",
            sectionId1: "nose-eye-allergies",
            sectionHeader2: "Food allergies",
            sectionCopy2: "Food allergies are a collection of allergic responses to a food substance. Food allergies are relatively common and affect 8 percent of children younger than five and as many as 4 percent of adults. They occur when the immune system identifies typically non-harmful foods as a threat to the immune system. The risk factors of food allergies include having a family history of food allergies or other allergic responses such as hives, hay fever and asthma. Having an allergy to one food item may increase the likelihood of developing other food allergies. Symptoms include an itchy mouth, hives, swelling, wheezing, nasal congestion and digestive symptoms like pain, nausea, vomiting and feeling faint. They can be mild to life-threatening in severity. Food allergies may affect lifestyle by requiring new diet changes and restricting options when eating outside of the home. There are many ways in which doctors may diagnose a food allergy, including taking symptom history, family history, performing physical examination, blood tests, skin tests, beginning an elimination diet and oral food challenges. Treatment for reactions can include antihistamines, though some may require epinephrine and an emergency room visit.",
            sectionId2: "food-allergies",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: "",
            sectionHeader1: "Medication reactions",
            sectionCopy1: "An allergic reaction to medication describes adverse reactions to a particular medication or ingredient in a medication. When your immune system identifies a drug substance as a threat to your health, it will try to protect itself by responding to the substance as if it were dangerous. The immune response presents as allergy symptoms such as rash, hives, itching, wheezing and difficulty breathing, fever or runny nose. Those who have other allergies, including seasonal or food allergies, a history of drug allergies, exposure to a drug with high doses or repeated use are at increased risk of medication reactions. Medication reactions may impact lifestyle by requiring adjustments to medications to be made or to avoid certain medications. Testing for a medication reaction can be done through a physical exam, skin tests or blood tests. Treating a medication reaction can include addressing withdrawal symptoms, using antihistamines and steroids to control reactions and treating serious reactions with epinephrine. If a medication causes a reaction, but a doctor still believes its use is necessary for treatment, desensitization and graded challenges may be used to improve tolerance. ",
            sectionId1: "medication-reactions",
            sectionHeader2: "Stinging insect venom",
            sectionCopy2: "Insect bites and stings can produce allergic reactions ranging from mild to severe. These allergies affect thousands of people yearly, occurring in 0.4 percent to 0.8 percent of children and 3 percent of adults. An allergic reaction to insect bites or stings results from the immune system detecting the insect’s venom as a threat to your well-being. An allergy to venom can develop at any point in someone’s life, but are more common in people who have had previous allergic reactions to insect bites or stings. Allergic reactions to insect venom include hives, swelling, vomiting and pain. Mild reactions will include redness and pain at the site of the bite or sting, but more severe reactions can be life-threatening and require medical attention. Those with allergies to insect venom will need to stay aware of insects and avoid interacting with them to prevent a potential bite or sting. Mild reactions can be treated by disinfecting the area and applying a cold compress to reduce swelling. Severe reactions will need epinephrine injections and antihistamines and corticosteroids may be used to ease discomfort in prolonged reactions. These allergies can be managed through immunotherapy.",
            sectionId2: "stinging-insect-venom",
            cssClass: "border-bottom",
            id: 2
        },
        {
            imageUrl: allergyconditions3,
            sectionHeader1: "Skin – hives, eczema, contact dermatitis",
            sectionCopy1: "Skin allergic reactions such as hives, eczema and contact dermatitis are adverse reactions to a typically harmless substance. When exposed to an allergen, the skin may develop redness, itchiness, flaking and cracking of the skin and swelling. Common allergens that trigger skin reactions include latex, poison ivy, extreme temperatures, sun or water exposure, certain medications and pollen. Skin reactions affect a patient’s comfort, but usually are only temporary. These allergies can be treated by avoiding contact with allergens, using products for sensitive skin or using an over-the-counter antihistamine or steroid cream.",
            sectionId1: "skin-hives-eczema",
            sectionHeader2: "Asthma",
            sectionCopy2: "Asthma is a chronic condition that affects your lungs and breathing. It’s a common condition affecting over 3 million people per year in the United States alone. Asthma restricts breathing by inflaming and narrowing the airways and producing mucus. Risk factors for asthma include being exposed to secondhand smoke as a child, exposure to substances in the workplace like chemicals or dust, family history of asthma, being of Black or Puerto Rican descent, and having other conditions like allergies or obesity. In children, boys more commonly have asthma than girls, and women more commonly have asthma than men in adult patients. Symptoms include difficulty breathing, consistent cough, wheezing and chest pain. Those with severe asthma may have trouble going to work and school, maintaining a social life and exercising. Asthma can be diagnosed through a FeNO test, which measures nitric oxide in your breath and gives insight into lung inflammation and spirometry, which measures your lung capacity and rate of breathing. Treatments include rescue inhalers to treat symptoms and controller inhalers to prevent symptoms from occurring.",
            sectionId2: "asthma",
            cssClass: "border-bottom",
            id: 3
        },
        {
            imageUrl: "",
            sectionHeader1: "Immune deficiencies",
            sectionCopy1: "Immune deficiencies occur when the immune system underreacts to threats to the body. Immune deficiencies affect approximately 6 million people across the globe. When an immune deficiency is present, the body is less able to fight off infections, viruses and parasites threatening health and wellness. Several types of immune deficiencies exist, including severe combined immune deficiency (SCID), common variable immune deficiency (CVID), and human immunodeficiency virus (HIV), acquired immune deficiency syndrome (AIDS) and drug-induced immune deficiency, all reducing the immune system’s ability to respond to threats. Those with a family history of immune deficiencies, who don’t get enough protein or sleep, and those with cancer or who are undergoing cancer treatment are at increased risk of developing an immune deficiency. Symptoms include frequent infections, abdominal pain and unintended weight loss. Those with immune deficiencies must use extra caution when exposed to potential threats, such as flu season or the COVID-19 virus. Testing for an immune deficiency includes physical exam and blood tests that examine white blood cell count, T-cell count and immunoglobulin levels. Treatment includes prevention and treatment of infections when they occur and using medications and procedures such as antibiotics, antiviral suppressants and bone marrow transplants to strengthen the immune system.",
            sectionId1: "immune-deficiencies",
            sectionHeader2: "Severe allergic reactions",
            sectionCopy2: "A severe allergic reaction, or anaphylaxis, is a serious and life-threatening immune response to an allergen. This is a common condition affecting one in five people in the United States. When the immune system has an extreme reaction to an allergen, it tries to protect the body by responding with swelling and vomiting to isolate the allergen. Risk factors for anaphylaxis include previous severe allergic reactions and existing allergic conditions. Symptoms of a severe allergic reaction impact more than one organ system, causing swelling, difficulty breathing, fainting, vomiting or diarrhea. People at risk of having a severe allergic reaction must take preventive and cautionary measures to reduce exposure to allergens. Treatment options include working with a doctor to create a prevention plan, keeping epinephrine on-hand for preventing a life-threatening response, and addressing anaphylaxis with emergency care and epinephrine.",
            sectionId2: "severe-allergic-reactions",
            cssClass: "border-bottom",
            id: 4
        },
        {
            imageUrl: allergyconditions2,
            sectionHeader1: "Mast cell disorders",
            sectionCopy1: "Mast cell disorders are genetic disorders in which immune cells called mast cells grow at abnormal rates and produce unpleasant symptoms in patients. These cells protect the body from invaders like parasites, bacteria and viruses and are involved in allergic reactions. Symptoms of mast cell disorders range from few to disabling and affect many organ systems, including responses like itching, nausea, brain fog, congestion, blood pressure changes, headaches, anxiety and depression, and in severe cases they can cause anaphylaxis. Those affected will need to carefully monitor their responses to temperature changes, stress, exercise, exposure to insect venom, certain dyes and perfumes, and certain foods and medications. Diagnosing a mast cell disorder requires blood tests, bone density and CT scans, and tissue biopsies. Treatment may be needed to address mast cell damage and includes medications to block mast cell reactions and address allergic responses.",
            sectionId1: "mast-cell-disorders",
            sectionHeader2: "Recurrent swelling (angioedema)",
            sectionCopy2: "Angioedema is repeated swelling beneath the skin that takes place in response to a food allergen, a medication or an unknown cause. Angioedema, like other immune conditions, is the result of a heightened immune response as the body tries to protect itself from foreign invaders and perceived threat. Those with a history or family history of hives or angioedema are at an increased risk. Symptoms include swelling of the skin, most commonly around the face, hands and feet, hives, stomach pain, breathing difficulties and dizziness. People who are diagnosed with angioedema may experience recurring bouts of swelling in response to a trigger and may be at risk of a life-threatening response. Testing for angioedema includes a physical examination, medical history interview and potential skin and blood tests. Treatment options depend on the type of angioedema a patient has, but can include antihistamines, steroids and home remedies.",
            sectionId2: "recurrent-swelling",
            cssClass: "border-bottom",
            id: 5
        },
        {
            imageUrl: "",
            sectionHeader1: "Pediatric allergies",
            sectionCopy1: "Allergies in childhood can be common. They can cause other conditions like asthma. If untreated, allergies can also lead to frequent headaches, snoring, sleep apnea or an overbite. Allergies may affect a child’s performance in school, sleep patterns and general quality of life. It’s important to treat allergies correctly at a young age to prevent them from continuing into adulthood. Our experienced pediatric allergy specialist is a top allergy and immunology with over 36 years of experience. He is an expert in diagnosing allergies in children and providing the right care to treat symptoms. ",
            sectionId1: "pediatric-allergies",
            sectionHeader2: "",
            sectionCopy2: "",
            sectionId2: "",
            cssClass: "",
            id: 6
        },

        //More templates ready below:
        //
        // {
        //     imageUrl: cancerswetreat6,
        //     sectionHeader1: "title",
        //     sectionCopy1: "body",
        //     sectionId1: "id",
        //     sectionHeader2: "title2",
        //     sectionCopy2: "body2",
        //     sectionId2: "id2",
        //     cssClass: "border-bottom",
        //     id: 7
        // },
        // {
        //     imageUrl: cancerswetreat6,
        //     sectionHeader1: "title",
        //     sectionCopy1: "body",
        //     sectionId1: "id",
        //     sectionHeader2: "title2",
        //     sectionCopy2: "body2",
        //     sectionId2: "id2",
        //     cssClass: "border-bottom",
        //     id: 8
        // },
        // {
        //     imageUrl: cancerswetreat6,
        //     sectionHeader1: "title",
        //     sectionCopy1: "body",
        //     sectionId1: "id",
        //     sectionHeader2: "title2",
        //     sectionCopy2: "body2",
        //     sectionId2: "id2",
        //     cssClass: "border-bottom",
        //     id: 9
        // },
    ],
    buttons: [
        {
            id: 1,
            buttonCopy: "Nose and eye allergies",
            buttonLink: "#nose-eye-allergies"
        },
        {
            id: 2,
            buttonCopy: "Food allergies",
            buttonLink: "#food-allergies"
        },
        {
            id: 3,
            buttonCopy: "Medication reactions",
            buttonLink: "#medication-reactions"
        },
        {
            id: 4,
            buttonCopy: "Stinging insect venom",
            buttonLink: "#stinging-insect-venom"
        },
        {
            id: 5,
            buttonCopy: "Skin – hives, eczema, contact dermatitis",
            buttonLink: "#skin-hives-eczema"
        },
        {
            id: 6,
            buttonCopy: "Asthma",
            buttonLink: "#asthma"
        },
        {
            id: 7,
            buttonCopy: "Immune deficiencies",
            buttonLink: "#immune-deficiencies"
        },
        {
            id: 8,
            buttonCopy: "Severe allergic reactions",
            buttonLink: "#severe-allergic-reactions"
        },
        {
            id: 9,
            buttonCopy: "Mast cell disorders",
            buttonLink: "#mast-cell-disorders"
        },
        {
            id: 10,
            buttonCopy: "Recurrent swelling (angioedema)",
            buttonLink: "#recurrent-swelling"
        },
        {
            id: 11,
            buttonCopy: "Pediatric allergies",
            buttonLink: "#pediatric-allergies"
        },


    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default CANCERS_WE_TREAT_DATA;