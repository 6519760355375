import { createSelector } from 'reselect';

const PROVIDER_ID_MAP = {
    'ricardo-vinuya': 1,
    'robert-lampert': 3,
    'daniel-hering': 4,
    'lisa-reiter': 5,
    /*'patty-roberts': 6,*/
    'mona-liu': 7,
    'simhean-lee': 8,
}

const selectProviders = state => state.provider;

export const selectProviderSections = createSelector(
    [selectProviders],
    provider => provider.sections
)

export const selectIndividualProvider = providerUrlParam =>
    createSelector(
        [selectProviderSections],
        providers =>
            providers.find(
              provider => provider.id === PROVIDER_ID_MAP[providerUrlParam]
        )
    );
