import React from 'react';
import ProviderGallery from "../../components/provider-gallery/provider-gallery.component";
import HeaderComponent from "../../components/header/header.component";
import {Route} from "react-router";
import './providers-gallery.styles.scss';
import PROVIDERS_DATA from './provider.data'
import heroImage from '../../img/OCLA/providers/providers-hero.png';
import {Helmet} from "react-helmet";
import ButtonsDirectoryComponent from "../../components/buttons-directory/buttons-directory.component";
import {hideMask} from "../../components/navigation/navigation.component";
const pageTitle = "Meet our providers";

const ProvidersPage = ( {match} ) => (
    <>
            <Helmet>
                <title>{pageTitle} | Optum Care Lung and Allergy</title>
            </Helmet>
            <main className='page-body' onClick={hideMask}>
                <HeaderComponent title={pageTitle} innerCopy="" imageUrl={heroImage}/>
                {/* <ButtonsDirectoryComponent buttons={PROVIDERS_DATA.buttons}/> */}
                <Route exact path={`${match.path}`} component={ProviderGallery}/>
            </main>
    </>
)

export default ProvidersPage;
