import cancerswetreat1 from '../../img/Cancer_Center_Building_Photos/OC_Cancer_Center/building_pic_1.png';
import cancerswetreat2 from '../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../img/cancers-we-treat-4.png';
import patientresourcesbreak1 from '../../img/Cancer_Center_Building_Photos/OC_Cancer_Center/cancer_building_interior_1.png';
import image1 from "../../img/Cancer-Care-Images/patient_interior_5050.png";

const PATIENT_RESOURCES_GENERAL_DATA = {
    sections: [
        {
            imageUrl: "",
            htmlCopy1: ``,
            sectionId1: "patient-resources-1",
            cssClass: "",
            id: 1
        }

    ],
    textSection1: [
        {
            title2: "Financial assistance",
            bodyTextLine1: "The cost of treatment and cancer care can be overwhelming. That’s why we connect patients to a cancer care advocate who can offer counseling to patients and their families. We’re here to help cancer patients navigate all their financial concerns so that bills never pile up. We offer support and financial guidance to all our patients so they can find programs, resources and solutions to alleviate the stress of paying for treatment while potentially reducing or eliminating the cost.",
            bodyTextLine2: "Our cancer care advocates can provide information on specialized programs and services at both the national and local level that are designed to help make it easier for patients to access financial assistance. Rest assured that at OptumCare Cancer Center, patients and their families have a knowledgeable and supportive team to guide them through insurance verifications and various financial processes.",
            customDivClass:'cards-no-border col-md-8 col-sm-12 pt-5',
            customSectionClass:'container-fluid',

        }
    ],

    textSection2: [
        {
            title2: "Cancer Center",
            bodyTextLine1: "This is where individualized care begins—with a vast array of resources. The OptumCare® Cancer Center was built in a way that is both holistic and patient-focused. Our state-of-the-art, 55,000-square-foot facility is to receive innovative treatment options for a wide range of oncologic malignancies. We provide a wide range of medical and supportive services including:",
            customDivClass:'cards-no-border col-md-8 col-sm-12 pt-5',
            customSectionClass:'container-fluid',

        }
    ],

    interiorSection: [
        {
            htmlCopy: `
                        <ul>
                            <li>Medical oncology</li>
                            <li>Infusion suite</li>
                            <li>Imaging</li>
                            <li>Breast care</li>
                            <li>Clinical research</li>
                            <li>Genetic counseling</li>
                            <li>Palliative care</li>
                            <li>Patient resource library</li>
                            <li>Café java</li>
                            <li>Auditorium</li>
                        </ul> `,
            imageUrl: "",
            imageHeight: "",
            imageLeft: true,
            id: 1

        }
    ],

    section2: [
        {
                imageUrl: '',
                sectionHeader1: "New patient information",
                htmlCopy1: `<p>As an Optum Care Lung and Allergy Care patient, we want to make sure you have access to all the resources and services you need. </p> <br>`,
                sectionId1: "patient-resources-1",
                sectionHeader2: "Procedure consent form",
                htmlCopy2: `<p>Download our patient consent form to prepare for your upcoming appointment. </p>`,
                cssClass: "border-bottom",

                id: 3

        }
    ],

    section3: [
        {
            imageUrl: '',
            sectionHeader1: "Pay bill",
            htmlCopy1: `<p>You have many options for paying your bill: </p> <br>
                        <ul>
                            <li>To pay your bill over the phone, please call  <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b> </li>
                            <li>Pay by mail by sending a check. Please make the check out to <NAME>. To find the address for your provider’s office, click here.</li>
                            <li>Pay your bill in person by visiting your doctor’s office. </li>            
                        </ul>`,
            sectionId1: "patient-resources-1",
            sectionHeader2: "Request medical records",
            htmlCopy2: `<p>You can request your protected health information at any time. For assistance with requesting your Optum Care Lung and Allergy care records, please call  <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>. It may take 7 to 10 days to receive your health records. </p>`,
            cssClass: "border-bottom",
            id: 3

        }
    ],

    buttons: [

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default PATIENT_RESOURCES_GENERAL_DATA;