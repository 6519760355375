import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const MEDICAL_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "Blood tests",
            sectionCopy1: "Blood tests measure the amount of antibodies in the blood to determine whether an allergy is present. Some tests measure the antibodies present at any given time, while others measure antibodies only after exposure to a specific allergen. A lower amount of antibodies is connected to a normal immune response, and a higher amount of antibodies may signify an allergy. When getting a blood test, a health care worker will take a sample of blood from a vein using a small needle. The test is usually quick and relatively painless. The results will help a doctor develop a prevention and treatment plan.",
            sectionId1: "blood-tests",
            sectionHeader2: "Skin tests ",
            sectionCopy2: "Skin tests measure an allergic response that presents on the skin in response to an allergen. These tests are commonly used and are safe for most children and adults. A doctor may avoid skin tests if the patient has a history of severe allergic responses, has skin conditions like eczema or is taking medications that could interfere with the results of the test. Skin tests are typically conducted on the arms or back. Results of skin tests can help doctors diagnose and treat allergies and allergic reactions. ",
            sectionId2: "skin-tests",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: "",
            sectionHeader1: "Oral food challenges",
            sectionCopy1: "Oral food challenges are a method of determining whether a food allergy is present. These tests are typically used when other forms of testing, like blood or skin tests, provide uncertain results. When conducting the test, a health care worker will offer small servings of food while monitoring the responses a patient may or may not have when eating. Preparing for an oral food challenge may require not eating or taking medications before the test is given.",
            sectionId1: "oral-food-challenges",
            sectionHeader2: "Penicillin test",
            sectionCopy2: "A penicillin skin test is used to determine whether someone is allergic to penicillin. Like other skin tests, a health care worker will apply a small amount of the allergen to the skin and monitor for an allergic response. If a penicillin allergy is present, the skin will become red and itchy at the affected area. ",
            sectionId2: "penicillin-test",
            cssClass: "border-bottom",
            id: 2
        },
        {
            imageUrl: "",
            sectionHeader1: "Patch test",
            sectionCopy1: "A patch test is given to determine whether a substance triggers an allergic reaction on the skin. These tests may have reactions that occur days after testing. When undergoing a patch test, a health care worker will apply a patch to either the arm or back, which is worn for 48 hours. After the 48-hour period is over, a doctor will remove the patch and inspect the skin for irritation. If irritation is present, a diagnosis may be made for a skin allergy. ",
            sectionId1: "patch-test",
            sectionHeader2: "",
            sectionCopy2: "",
            sectionId2: "",
            cssClass: "",
            id: 3
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default MEDICAL_ONCOLOGY_DATA; 
