import image1 from "../../img/OCLA/homepage/Rectangle 7.png";
import image2 from "../../img/OCLA/homepage/home-5050-2.png";
import galleryimage4 from "../../img/OCLA/homepage/OCLA-home-columns-2.png";
import galleryimage3 from "../../img/OCLA/homepage/4column-2.png";
import galleryimage2 from "../../img/OCLA/homepage/4column_1.png";
import galleryimage1 from "../../img/OCLA/homepage/4column_3.png";

const HOMEPAGE_DATA = {
    interiorSections: [
        {
            title: "Care centered around your unique needs",
            bodyCopy: "Most of our health care providers have been caring for their patients for decades. Your doctor will spend time getting to understand your history and concerns to best determine the cause of your condition. Then, they’ll guided you through all your treatment options. You’ll work together to develop a treatment plan personalized to your condition.",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Guiding and supporting you through each step",
            bodyCopy: "We know any diagnosis can be overwhelming. That’s why we make it a priority to help you understand what to expect. You’ll be provided the education and resources you need to successfully manage your condition. We’ll make sure you feel informed and taken care of along the way. As our patient, our main goal is to help you breathe easier, feel better and enjoy overall wellness.",
            imageUrl: image2,
            imageLeft: true,
            id: 2
        },
        // {
        //     bodyCopy: 'We know any diagnosis can be overwhelming. That’s why we make it a priority to help you understand what to expect. You’ll be provided the education and resources you need to successfully manage your condition. We’ll make sure you feel informed and supported through each step.',
        //     imageUrl: image3,
        //     id: 3
        // }
    ],
    galleryItems : [
        {
            title: 'Onsite diagnostics',
            imageUrl: galleryimage2,
            bodyCopy: 'Many tests can be done in-house during the appointment. This includes imaging like CT scans, pulmonary function tests, and allergy testing to help with diagnosing a condition.',
            buttonText: '',
            linkUrl: '#',
            id: 1
        },
        // {
        //     title: 'Extra amenities',
        //     imageUrl: galleryimage3,
        //     bodyCopy: "We want your experience to be pleasant. That's why we offer complimentary valet parking and have a Starbucks nearby for you to enjoy food and beverages.",
        //     buttonText: '',
        //     linkUrl: '#',
        //     id: 2
        // },
        {
            title: 'Quick access to care',
            imageUrl: galleryimage1,
            bodyCopy: "Ensuring our patients are seen in a timely manner is a priority. We triage each patient to get them the care they need, when they need it.",
            buttonText: '',
            linkUrl: '#',
            id: 3
        },
        {
            title: 'Easy referrals',
            imageUrl: galleryimage4,
            bodyCopy: `Our University of Nevada, Las Vegas (UNLV) partnership makes for a simple referral process for certain procedures. We'll refer to an outpatient rehabilitation center in town when needed.`,
            buttonText: '',
            linkUrl: '#',
            id: 4
        },

    ],
    textSection: {
        title: 'Serving Las Vegas through orthopaedic excellence',
        customTitle1Class: 'dash',
        bodyTextLine1:'We offer highly specialized orthopaedic care in a professional and friendly setting. Our focus is delivering outstanding care to every patient experiencing orthopaedic-related challenges and injuries. We approach each patient with compassion and understanding.',
        bodyTextLine2: 'As our patient, we’ll review all your options and provide educational resources to develop a treatment plan personalized to your condition. We’re committed to providing you with the highest quality of care and treatment available.',
        customSectionClass: 'container-fluid col-12 col-lg-8'
    },

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default HOMEPAGE_DATA;
