import lungconditions1 from '../../../img/OCLA/lung-conditions/edited/lung-conditions-1.png';
import lunconditions2 from "../../../img/OCLA/lung-conditions/edited/lung-conditions-3.png";
import lunconditions3 from "../../../img/OCLA/lung-conditions/edited/lung-conditions-sleep.png";

const CANCERS_WE_TREAT_DATA = {
    sections: [
        {
            imageUrl: lungconditions1,
            sectionHeader1: "Chronic obstructive pulmonary disease (COPD)",
            sectionCopy1: "COPD occurs when there are structural changes of the lungs from chronic inflammation. In this condition, the airway becomes narrow and the air sacs (alveoli) in the lungs become damaged. This makes airflow out of the lungs more difficult. Risk factors include smoking, exposure to certain chemicals and genetic factors. Symptoms include coughing, wheezing, excess mucus, shortness of breath and use of accessory muscles in the neck and abdomen to breathe. As COPD worsens, it can become more difficult to do daily activities like walking or completing chores around the house. COPD can be managed with both medication, rehabilitating the lungs through training and exercises, and lifestyle modifications.",
            sectionId1: "chronic-pulmonary",
            sectionHeader2: "Asthma",
            sectionCopy2: "Asthma is a common condition that affects roughly 20 percent of people in the United States. Asthma is much more common in childhood. There are cases, however, when the first asthma attack will occur as an adult. It happens when a trigger causes inflammation in the airway that leads to narrowing. This can range from mild to life threatening in severity. Many things can be triggers such as exercise, smoke, environmental allergens, viral infections and pet dander. Symptoms include wheezing, shortness of breath and feelings of anxiety. Our specialist will evaluate the severity of your asthma through examination and testing. Then, they’ll come up with a treatment plan for long-term management.",
            sectionId2: "asthma",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: lunconditions2,
            sectionHeader1: "Emphysema",
            sectionCopy1: "Emphysema is a form of COPD. This condition and its symptoms get worse over time. There’s damage to the alveoli in the lungs, where air exchange takes place. This can make it difficult to breathe normally. Emphysema can be caused by exposure to environmental pollutants, chemicals and smoking. Symptoms include trouble breathing, wheezing, shortness of breath, increased mucus and use of accessory muscles. As emphysema becomes more advanced, symptoms become more severe and weight loss can occur. This means normal daily activities can become harder to do because of fatigue. COPD can be managed by lung rehabilitation with training and exercises, lifestyle modifications, and medication therapy.",
            sectionId1: "emphysema",
            sectionHeader2: "Sarcoidosis",
            sectionCopy2: "Sarcoidosis is a disease that causes inflammation of multiple systems. Granulomas or clumps of immune cells clump together and can be found in the lungs, skin, joints and eyes. With sarcoidosis, organs like the heart and kidneys can also be affected. The cause of sarcoidosis is usually due to both environmental and genetic factors. With sarcoidosis, the symptoms include shortness of breath, fatigue, a dry cough and fatigue. Patients may also develop lesions or papules on the skin. Oftentimes sarcoidosis goes away on its own without any treatment. In more severe cases, a patient will be prescribed steroids to help treat symptoms.",
            sectionId2: "sarcoidosis",
            cssClass: "border-bottom",
            id: 2
        },
        {
            imageUrl: "",
            sectionHeader1: "Lung cancer",
            sectionCopy1: "Lung cancer affects more than 200,000 people in the United States every year. Smokers and those with a family history of lung cancer have a higher risk of developing lung cancer. Other risk factors include air pollution and exposure to certain materials like asbestos and radon. When lung cancer develops, cells in the lungs become mutated in a way that causes them to grow faster than normal cells. Symptoms of lung cancer include chest pain, shortness of breath, fatigue, coughing, wheezing and unexplained weight loss. Lung cancer can make daily activities difficult due to low energy from fatigue. Treatments for lung cancer vary based on the stage of the disease, but include chemotherapy, radiation therapy and surgical options to manage the disease. ",
            sectionId1: "lung-cancer",
            sectionHeader2: "Pulmonary nodules",
            sectionCopy2: "Pulmonary nodules are abnormal growths in the lungs. They can be caused by a lung infection or scar tissue in the lungs. Individuals who are over the age of 40, female, and a family history of lung cancer are at a higher risk of developing pulmonary nodules. Pulmonary nodules don’t usually cause symptoms in patients, but can cause a cough in rare cases. Pulmonary nodules can be detected through imaging of the chest such as an X-ray or CT scan. When detected, they should be tested for malignancy to see if they might be cancerous. Treatment options include supportive care, managing and monitoring nodules for developments with additional imaging or biopsies. ",
            sectionId2: "pulmonary-nodules",
            cssClass: "border-bottom",
            id: 3
        },
        {
            imageUrl: lunconditions3,
            sectionHeader1: "Pleural effusions and diseases",
            sectionCopy1: "Pleural effusion is a build-up of fluid within the layers of the lungs and chest. This type of fluid build-up can result from pneumonia or as a complication of another condition, such as kidney, heart or liver disease. Risk factors include contact with asbestos, smoking and drinking alcohol, which can lead to conditions that produce pleural effusions. Symptoms include coughing, shortness of breath and chest pain. Symptoms depends on the amount of fluid in the lungs. Pleural effusion can impact a patient’s life by restricting their activity and comfort level due to difficulty breathing. A diagnosis is made through a physical exam and potential imaging tests or testing the fluid in the lungs. To treat pleural effusion, it’s most effective to treat its cause. Sometimes, if no cause is known, a doctor may perform thoracentesis, a procedure to drain the fluid in the lungs.",
            sectionId1: "pleural-effusions",
            sectionHeader2: "Sleep apnea",
            sectionCopy2: "Sleep apnea is a sleep disorder in which patients’ breathing starts and stops repeatedly while sleeping. An estimated 2 percent to 9 percent of adults in the United States are affected by sleep apnea. Sleep apnea results from the muscles of the throat relaxing during sleep which prevents steady, normal breathing. The risk of developing sleep apnea increases with age and is higher in males and those with obesity. Symptoms of sleep apnea include loud snoring, feeling sleepy even after getting a full night’s rest, dry mouth and throat, insomnia or nightmares, and mood changes such as irritability and depression. Sleep apnea can have an effect on a patient’s quality of life and impact their day-to-day functioning through fatigue and drowsiness. Treatment for sleep apnea often includes lifestyle changes like weight loss and using a continuous positive airway pressure (CPAP) machine to help ensure steady breathing throughout the night. ",
            sectionId2: "sleep-apnea",
            cssClass: "border-bottom",
            id: 4
        },
        {
            imageUrl: "",
            sectionHeader1: "Interstitial lung disease",
            sectionCopy1: "Interstitial lung disease is a group of disorders that result in scar tissue developing in the lungs. As scarring develops, it causes the lungs to become stiff and breathing becomes difficult. People who have been exposed to hazardous materials for a long period of time, including asbestos and coal dust, as well as those who have a preexisting autoimmune disorder are at the highest risk. Symptoms of interstitial lung disease are a persistent dry cough and shortness of breath. Lack of oxygen in the blood and difficulty breathing can cause fatigue and exhaustion both at rest and after movement. Over time, the damage caused to the lungs will get worse. treatment depends on the underlying cause. Medications like steroids are often used to help treat and manage interstitial lung disease. ",
            sectionId1: "interstitial-lung-disease",
            sectionHeader2: "Infections",
            sectionCopy2: "Lung infections include bronchitis, bronchiolitis and pneumonia. These infections develop when bacteria or a virus, such as the flu virus or pneumonia bacteria, enters the body which can damage the lungs. People under the age of three or over the age of 65 and hospitalized patients are at the highest risk. Symptoms of lung infections include a wet cough, chest pain, shortness of breath or wheezing, and flu-like symptoms including body aches and fever. A physical examination and complete medical history along with imaging such as an X-ray or CT scan may be used to diagnose a lung infection. Other tests may be performed, including spirometry to measure breathing rate, or pulse oximetry to measure blood oxygen levels, swabbing mucus for testing and blood tests. Treatment includes antibiotics or home remedies.",
            sectionId2: "infections",
            cssClass: "border-bottom",
            id: 5
        },

    ],
    buttons: [
        {
            id: 1,
            buttonCopy: "Chronic obstructive pulmonary disease (COPD)",
            buttonLink: "#chronic-pulmonary"
        },
        {
            id: 2,
            buttonCopy: "Asthma",
            buttonLink: "#asthma"
        },
        {
            id: 3,
            buttonCopy: "Emphysema",
            buttonLink: "#emphysema"
        },
        {
            id: 4,
            buttonCopy: "Sarcoidosis",
            buttonLink: "#sarcoidosis"
        },
        {
            id: 5,
            buttonCopy: "Lung cancer",
            buttonLink: "#lung-cancer"
        },
        {
            id: 6,
            buttonCopy: "Pulmonary nodules",
            buttonLink: "#pulmonary-nodules"
        },
        {
            id: 7,
            buttonCopy: "Pleural effusions and diseases",
            buttonLink: "#pleural-effusions"
        },
        {
            id: 8,
            buttonCopy: "Sleep apnea",
            buttonLink: "#sleep-apnea"
        },
        {
            id: 9,
            buttonCopy: "Interstitial lung disease",
            buttonLink: "#interstitial-lung-disease"
        },
        {
            id: 10,
            buttonCopy: "Infections",
            buttonLink: "#infections"
        },

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default CANCERS_WE_TREAT_DATA;