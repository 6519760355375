import React from 'react';
import ProviderThumbnail from "./provider-thumbnail/provider-thumbnail.component";
import "./provider-gallery.styles.scss"
import {connect} from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectProviderSections } from "../../redux/provider/provider.selectors";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
import { getProvidersBySpecialty } from "../../main";

const ProviderGallery = ({ sections: providers }) => {


    var lung = getProvidersBySpecialty('Lung');

    var allergy = getProvidersBySpecialty('Allergy');




    return (
        <div className="provider-gallery-component">

            <DividerArrowDownComponent title="Lung" divId="Lung"/>
            <div className="row gallery-row centered-div">
                {
                    lung.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="Allergy" divId="Allergy"/>
            <div className="row gallery-row centered-div">
                {
                    allergy.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

        </div>)
};

const mapStateToProps = createStructuredSelector({
    sections: selectProviderSections
});

export default connect(mapStateToProps)(ProviderGallery);
