import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import Image2Paragraphs from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import MEDICAL_ONCOLOGY_DATA from "./allergy-treatments.data";

import ctaImage from '../../../img/OCLA/CTA/CTA_OCLA.png';
import heroImage from '../../../img/OCLA/allergy-treatment/allergy-treatment-hero.png';
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../../components/navigation/navigation.component";
import SingleParagraph from "../../../components/single-paragraph/single-paragraph";

class AllergyTreatmentsPage extends React.Component {
    pageTitle = "Allergy treatments";
    state = MEDICAL_ONCOLOGY_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Lung and Allergy</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />
                    <div className='row justify-content-around'>
                    </div>

                    {
                        this.state.sections.map(({id, ...otherSectionProps}) => (
                            <Image2Paragraphs key={id} {...otherSectionProps} />
                        ))
                    }
                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
                
            </div>
        );
    }   
}

export default AllergyTreatmentsPage;