import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const MEDICAL_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Treatment can vary depending on the type of lung condition. Your specialist will review all test results and recommend the best treatment plan. Some treatments may be done in-house. For conditions that need more specific and specialized treatment, we’ll refer to one of our trusted community partners. </p>`,
            sectionId1: "medical-oncology-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    To make an appointment with one of our specialists, please call: 
                    <br/><br/>
                    <a href='tel:+17027248844'>1-702-724-8844</a>, <b>TTY 771</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default MEDICAL_ONCOLOGY_DATA; 
