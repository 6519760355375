import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import Image2Paragraphs from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import CANCERS_WE_TREAT_DATA from "./allergy-conditions.data";

import ctaImage from '../../../img/OCLA/CTA/CTA_OCLA.png';
import heroImage from '../../../img/OCLA/allergy-conditions/allergy-hero-1.png';
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../../components/navigation/navigation.component";

class AllergyConditionsPage extends React.Component {
    pageTitle = "Allergy conditions";
    state = CANCERS_WE_TREAT_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Lung and Allergy</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />
                    <div className='row justify-content-around'>
                        <div className="col-md-8 col-sm-12">
                            <p>Allergies can be frustrating and affect your daily life. From seasonal allergies to severe allergic reactions our specialists will help you find the right treatment to get relief from your symptoms.</p>
                        </div>
                    </div>
                    <ButtonsDirectoryComponent buttons={this.state.buttons}/>
                    {
                        this.state.sections.map(({id, ...otherSectionProps}) => (
                            <Image2Paragraphs key={id} {...otherSectionProps} />
                        ))
                    }
                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
                
            </div>
        );
    }
}

export default AllergyConditionsPage;